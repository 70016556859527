import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Projects from './pages/Projects/Project';
import Experience from './pages/Experience/Experience';
import Skill from './pages/Skills/Skill';


function App() {
  return (
    <div className='Main'>
      <Header/>
      <Home/>
      <About/>
      <Projects/>
      <Skill/>
      {/* <Experience/> */}
      <Contact/>
      <Footer/>
    </div>
    
  );
}

export default App;
