import React from "react";
import './About.css'
import Resume from './Untitled document.pdf'
import mine from '../../assets/image/mine.png'


const About = () =>{
    return(
        <div className="about" id="About">
            <div className="about-detail">
                <span>About Me</span>
                <br/>
                <br/>
                <span>Hello there, I am Feven Menelik Seifu. I'm a highly motivated and hardworking individual who is always eager to learn new things. I have Bachelor's degree in Electrical and Electronics Technology, and I'm currently pursuing a degree in Computer Science at NOVA.
                    <br/>
                    <br/>

Throughout my education and work experiences, I have developed strong problem-solving skills and the ability to work through tense situations. I enjoy tackling complex challenges and developing creative solutions that benefit both users and businesses.

I have a passion for technology, and I am skilled in full-stack programming. I'm constantly learning new technologies to stay up to date with the latest developments in the field.
<br/>
<br/>
In my spare time, I enjoy listening to K-pop music and watching shows to unwind. I believe that having a work-life balance is essential for success in any field, and I strive to maintain this balance in my own life.

I am excited to continue growing my skills and contributing to innovative projects that make a positive impact."
</span>
                {/* <span>I am hardworking and eager to learn new things.
                    <br/>
                I like to solve problems and able to work throug tensions.
                <br/>
                In my spare time I lke to listen kpop music and see shows also I like to sleep.</span> */}
                <a href={Resume} download>
                <button className="download-cvbtn">Download My Resume</button>
                </a>
            </div>
            <div className="home-right" >
    <img src={mine} alt="image" width={350} height={420} class="img"/>
  </div>
         </div>
    )
}

export default About