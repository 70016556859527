import React from "react";
import { Link } from "react-scroll";
import './Header.css'
function Header(props) {
    return (
        <div className="navbar">
            <div className="navbarleft">
                Feven
            </div>
            <div className="navbarright">
                <div className="navbarrightlist">
                    <ul style={{listStyleType: 'none'}}>
                        <Link spy={true} to='Header' smooth={true} activeClass="activeClass">
                        <li>Home</li>
                        </Link>
                        <Link spy={true} to='About' smooth={true} >
                        <li>About</li>
                        </Link>
                        <Link spy={true} to='Project' smooth={true} >
                        <li>Project</li>
                        </Link>
                        <Link spy={true} to='Skill' smooth={true} >
                        <li>Skill</li>
                        </Link>
                        {/* <Link spy={true} to='Experience' smooth={true} >
                        <li>Experience</li>
                        </Link> */}
                    </ul>
                </div>
                <Link spy={true} to='Contact' smooth={true}>
                <button className="contacBtn" >
                    Contact
                </button>
                </Link>
            </div>
        </div>
    )
  }
  
  export default Header;