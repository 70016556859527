import React from "react";
import './Footer.css'
import Linkedin from '@iconscout/react-unicons/icons/uil-linkedin'
import Github from '@iconscout/react-unicons/icons/uil-github'
function Footer(props) {
    return(
      <div className="footer">
        <div className="footer-content">
          <span>rutemikey1@gmail.com</span>
          <div className="footer-icons">
  <div className="logo1">
            <a href="https://www.linkedin.com/in/feven-seifu/" >
              <Linkedin color='blue' size='3rem'/>
            </a>
            </div>
    <div className="logo2">
            <a href="https://github.com/Feven98">
             <Github color='blue' size='3rem'/>
            </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  export default Footer;