import React from "react";
import './Skill.css'
import css from "../../assets/logo/css.png"
import javascript from "../../assets/logo/javascript.png"
import django from "../../assets/logo/django.webp"
import express from "../../assets/logo/express.jpeg"
import html from "../../assets/logo/html.png"
import mongodb from "../../assets/logo/mongodb.png"
import PostgreSQL from "../../assets/logo/PostgreSQL.svg"
import python from "../../assets/logo/python.png"
import react from "../../assets/logo/react.png"

const Skill = () => {
    return (
        <div className="skill" id="Skill">
            <div className="skill-detail">
                <h1>SKILLS</h1>
                <span className="addskill">The technologies I've been working with</span>
                <br />
                <div className="logo">
                <div className="css">
    <img src={javascript} alt="image" width={60} height={60}/>
    <h1>JavaScript</h1>
  </div>
  <div className="css">
    <img src={css} alt="image" width={60} height={60}/>
    <h1>CSS</h1>
  </div>
  <div className="css">
    <img src={html} alt="image" width={60} height={60}/>
    <h1>HTML</h1>
  </div>
  <div className="css">
    <img src={react} alt="image" width={60} height={60}/>
    <h1>React</h1>
  </div>
  <div className="css">
    <img src={python} alt="image" width={60} height={60}/>
    <h1>Python</h1>
  </div>
  <div className="css">
    <img src={django} alt="image" width={60} height={60}/>
    <h1>Django</h1>
  </div>
  <div className="css">
    <img src={express} alt="image" width={60} height={60}/>
    <h1>Express</h1>
  </div>
  <div className="css">
    <img src={mongodb} alt="image" width={60} height={60}/>
    <h1>MongoDB</h1>
  </div>
  <div className="css">
    <img src={PostgreSQL} alt="image" width={60} height={60}/>
    <h1>PostgresSQL</h1>
  </div>
                </div>

            </div>
        </div>
    )
}
export default Skill;