// import React from "react";
// import './Project.css';
// import ProjectContent from "./ProjectContent";
// import guzoblog from "../../assets/images/guzoblog.png";
// import foodrecipe from "../../assets/images/foodrecipe.png";
// import instagraph from "../../assets/images/instagraph.png"

// function Projects(props) {
//         return (
//             <div className="projects">
//                 <h1>My recente Projects</h1>
//                 <div className="projectlist">
//                     <ProjectContent name="Guzo-blog App" image={guzoblog}/>
//                     <ProjectContent name="Instagraph" image={instagraph}/>
//                     <ProjectContent name="Food Recipe App" image={foodrecipe}/>
//                 </div>
//             </div>
//         )
//     }

// export default Projects;
import React from "react"
import './Project.css';
import guzoblog from "../../assets/images/guzoblog.png";
import foodrecipe from "../../assets/images/foodrecipe.png";
import instagraph from "../../assets/images/instagraph.png"

const projectData = [
    {
        title: "Guzo-blog App",
        subtitle: "A blog app which is full-crud that lets a user create an account, create a blog, full user authentication, can upload photos using aws and like or comment on others posts.",
        techstack: "Python, Django, SQL, HTML, CSS, Bootstrap, Bulma, AWS",
        image: guzoblog,
        frontend: "https://github.com/Feven98/capstone_blog",
        deploy: "https://lit-gorge-39380.herokuapp.com/",
    },
    {
        title: "Instagraph",
        subtitle: "An Instagram clone where users can post photos, comment on others’ photos, and utilize geolocation",
        techstack: "HTML, CSS, JavaScript, React, Node.js, Mongoose, Postman",
        image: instagraph,
        frontend: "https://github.com/Feven98/project-3-fronted",
        backend: "https://github.com/Feven98/Project-3-backend",
        deploy: "https://instagraphfs.netlify.app/",
    },
    {
        title: "Food Recipe App",
        subtitle: "React frontend responsive web-app that  helps food lovers find new recipes and get instruction on how to prepare their favorite food.",
        techstack: "HTML, CSS, JavaScript, React, Node.js, Netlify",
        image: foodrecipe,
        frontend: "https://github.com/Feven98/Project-2",
        deploy: "https://foodrecipeapp1.netlify.app/",
    },
]

const Projects = () => {
    return (
        <div name="project" className="projects" id="Project">
            <div className="mainproject">
                <div className="pb-2">
                    <h1 className="projecttitle">
                        Project
                    </h1>
                    <span className="addrecente">My recente works</span>
                </div>
                <div className="projectcontent">
                    {projectData.map((project) => {
                        const list = (
                            <div className="projectlist">
                                <div className="project-box">
                                    <p className="project-title">
                                        {project.title}
                                    </p>
                                    <div className="imageproject">
                                        <div className="imageproject">
                                            <img
                                                src={project.image}
                                                className="imageproject"
                                            />
                                        </div>
                                    </div>
                                    <div className="py-4">
                                        <p className="project-subtitle">
                                            {project.subtitle}
                                        </p>
                                        <p className="project-skill">
                                            Technology Used:{" "}
                                            <span className="font-bold">{project.techstack}</span>
                                        </p>
                                        <div className="project-deploy">
                                            <a href={project.deploy} target="_blank" rel="noreferrer">
                                                <button className="projectBtn">
                                                    Live site
                                                </button>
                                            </a>
                                            {project.repo ? (
                                                <a
                                                    href={project.frontend}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <button className="projectBtn">
                                                        Code
                                                    </button>
                                                </a>
                                            ) : null}
                                            {project.frontend ? (
                                                <a
                                                    href={project.frontend}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <button className="projectBtn">
                                                        Frontend
                                                    </button>
                                                </a>
                                            ) : null}
                                            {project.backend ? (
                                                <a href={project.backend} target="_blank" rel="noreferrer">
                                                    <button className="projectBtn">
                                                        Backend
                                                    </button>
                                                </a>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        return list
                    })}
                </div>
            </div>
        </div>
    )
}

export default Projects