import React, { useState } from "react";
import './Contact.css'
import emailjs from '@emailjs/browser'
import { useRef } from "react";

function Contact(props) {
  const form = useRef();

  const [sent, setSent] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qcl3xhu', 'template_hgdo04j', form.current, 'xpyayGeU9XvU06MAT')
      .then((result) => {
          console.log(result.text);
          setSent(true);
      }, (error) => {
          console.log(error.text);
      });
  };
    return(
      <div className="contact-form"  id="Contact">
        <div className="contact-left">
          <div className="title">
            <span>Get in Touch</span>
            <br/>
            <span>Contact me </span>
            <div 
            className="blur s-blurl"
            style={{background: "#ABF1FF94"}}></div>
          </div>
        </div>

        <div className="contact-right">
          <form ref={form} onSubmit = {sendEmail}>
            <input type="text" name="user_name" className="user" placeholder="Name" />
            <input type="email" name="user_email" className="user" placeholder="Email" />
            <textarea name="message" className="user" placeholder="Message" />
            <input type="Submit" value="Send" className="btn" />
            <span>{sent && "Thank you for contacting me!"}</span>
            <div 
            className="blur c-blurl"
            style={{background: "var(--purple)"}}></div>
          </form>
        </div>
      </div>
    )
  }
  
  export default Contact;